var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButton',{attrs:{"text":"Novo tipo de assinatura","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"numero","sortDesc":false,"search":""},scopedSlots:_vm._u([{key:"termo_uso",fn:function(ref){
var col = ref.col;
return [_c('VueMarkdown',{attrs:{"source":col,"watches":['show', 'html', 'breaks', 'linkify', 'emoji'],"show":true,"html":true,"breaks":true,"linkify":true,"emoji":true,"typographer":true}})]}},{key:"dias_vigencia",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(col)+" dias ")]}},{key:"data_disponibilidade",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(col))+" ")]}},{key:"recorrencia",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.getRecorrencia(col))+" ")]}},{key:"recorrencia_entrega",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.getRecorrencia(col))+" ")]}},{key:"idtipoassinatura",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions,"loading":_vm.loadingRow[row.idtipoassinatura]},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }