<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Novo tipo de assinatura"
            type="primario"
            @click="onNew"
          />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="numero"
        :sortDesc="false"
        ref="datatable"
        search
      >
        <template v-slot:termo_uso="{ col }">
          <VueMarkdown
            :source="col"
            :watches="['show', 'html', 'breaks', 'linkify', 'emoji']"
            :show="true"
            :html="true"
            :breaks="true"
            :linkify="true"
            :emoji="true"
            :typographer="true"
          />
        </template>
        <template v-slot:dias_vigencia="{ col }"> {{ col }} dias </template>
        <template v-slot:data_disponibilidade="{ col }">
          {{ col | formatDate }}
        </template>
        <template v-slot:recorrencia="{ col }">
          {{ getRecorrencia(col) }}
        </template>
        <template v-slot:recorrencia_entrega="{ col }">
          {{ getRecorrencia(col) }}
        </template>
        <template v-slot:idtipoassinatura="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[row.idtipoassinatura]"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormButton from '@/components/form/FormButton'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { ASSINATURA_TYPE_REMOVE, RECORRENCIA } from '@/store/actions/assinatura'
import * as _ from 'lodash'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'ConfiguracaoTable',
  components: {
    DataTable,
    Panel,
    FormButtonMenu,
    FormButtonGroup,
    FormButton,
    MarketingModules,
    VueMarkdown
  },
  data: () => ({
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: 'Termo de uso', value: 'termo_uso' },
      { text: 'Vigência', value: 'dias_vigencia', align: 'center' },
      { text: 'Fator', value: 'fator', align: 'center' },
      {
        text: 'Disponibilidade',
        value: 'data_disponibilidade',
        align: 'center'
      },
      {
        text: 'Recorrência',
        value: 'recorrencia',
        align: 'center'
      },
      {
        text: 'Entrega',
        value: 'recorrencia_entrega',
        align: 'center'
      },
      { text: 'Opções', value: 'idtipoassinatura', clickable: false }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    loadingRow: {},
    modulosForm: [ModulesEnum.WEB_COMMERCE, ModulesEnum.COMMERCE_APP]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/subscription/type/datatable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    onNew() {
      this.$router.push(`tipos-assinaturas/novo`)
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a remoção deste tipo de assinatura '${item.nome}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.idtipoassinatura]: true
      }
      this.$store
        .dispatch(ASSINATURA_TYPE_REMOVE, item.idtipoassinatura)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Tipo de assinatura removida com sucesso'
          )
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.idtipoassinatura]: false
          }
        })
        .catch(err => {
          const erro = _.get(
            err,
            'responseJSON.message',
            'Não foi possível remover o tipo de assinatura'
          )
          this.$vueOnToast.pop('error', erro)
          this.loadingRow = {
            ...this.loadingRow,
            [item.idtipoassinatura]: false
          }
        })
    },
    getRecorrencia(tipoAssinatura) {
      return RECORRENCIA[tipoAssinatura] ? RECORRENCIA[tipoAssinatura].name : ''
    }
  }
}
</script>
